@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap'); 
@font-face { 
  font-family:ETmodules;
  font-display:block;
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/social/modules.eot);
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/social/modules.eot?#iefix) format("embedded-opentype"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/social/modules.woff) format("woff"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/social/modules.ttf) format("truetype"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/social/modules.svg#ETmodules) format("svg");
  font-weight:400;
  font-style:normal;
} 
@font-face { 
  font-family:FontAwesome;
  font-style:normal;
  font-weight:400;
  font-display:block;
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-regular-400.eot);
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-regular-400.eot?#iefix) format("embedded-opentype"),url(https:https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-regular-400.woff2) format("woff2"),url(//appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-regular-400.woff) format("woff"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-regular-400.ttf) format("truetype"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-regular-400.svg#fontawesome) format("svg");
} 
@font-face { 
  font-family:FontAwesome;
  font-style:normal;
  font-weight:900;
  font-display:block;
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-solid-900.eot);
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-solid-900.eot?#iefix) format("embedded-opentype"),url(https:https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-solid-900.woff2) format("woff2"),url(//appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-solid-900.woff) format("woff"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-solid-900.ttf) format("truetype"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-solid-900.svg#fontawesome) format("svg");
} 
@font-face { 
  font-family:FontAwesome;
  font-style:normal;
  font-weight:400;
  font-display:block;
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-brands-400.eot);
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-brands-400.eot?#iefix) format("embedded-opentype"),url(https:https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-brands-400.woff2) format("woff2"),url(//appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-brands-400.woff) format("woff"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-brands-400.ttf) format("truetype"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/fontawesome/fa-brands-400.svg#fontawesome) format("svg");
} 
  body {  
    font-size:100%;
    background:transparent;
    line-height:1.7em;
    font-family:Open Sans,Arial,sans-serif;
    color:#666;
    font-weight:500;
  }  
* { 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
} 

div { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    margin: 0; 
} 

body { 
    padding-top: 0px; 
    padding-right: 0px; 
    padding-bottom: 0px; 
    padding-left: 0px;
} 

body { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    line-height: 1;
} 

body { 
    font-family: Open Sans,Arial,sans-serif; 
    font-size: 14px; 
    color: #666; 
    background-color: #fff; 
    line-height: 1.7em; 
    font-weight: 500; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
} 

/*@media all{ 
  html { 
    --wp-admin--admin-bar--height: 32px; 
    scroll-padding-top: var(--wp-admin--admin-bar--height);
  } 
}     

html { 
    margin-top: 32px !important;
} */

html { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.team_with_background { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.team_section { 
    position: relative; 
    background-color: #fff; 
    background-position: 50%; 
    background-size: cover;
} 

@media (min-width: 981px){ 
  .team_section { 
    padding: 4% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .team_section { 
    padding: 54px 0;
  } 
}     

.team_section_0.team_section { 
    background-color: rgba(0,0,0,0.25)!important;
} 

div.team_section.team_section_0 { 
    background-blend-mode: multiply; 
    background-image: url(C:\Users\Dopor\Desktop\sportstake\src\images\sportstake7.jpg)!important;
} 

.team_row { 
    width: 80%; 
    max-width: 1080px; 
    margin: auto; 
    position: relative;
} 

.team_row { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .team_row { 
    padding: 2% 0;
  } 
}

.contact-container {
    text-align: center;
}
.contact-item {
    margin: 20px;
}
.contact-icon {
    font-size: 24px;
}
.contact-text {
    margin-top: 5px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    color: #000000;
}
.social-media {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.social-icon img {
    width: 35px;
    height: 35px;
    margin: 0 10px;
}

@media only screen and (min-width: 1200px){ 
  .team_row { 
    padding: 27px 0;
    display: flex;
  } 
}     

.team_row:after,.team_row_inner:after { 
    content: ""; 
    display: block; 
    clear: both; 
    visibility: hidden; 
    line-height: 0; 
    height: 0; 
    width: 0;
} 

.team_css_mix_blend_mode_passthrough { 
    mix-blend-mode: unset!important;
} 

.team_column { 
    float: left; 
    background-size: cover; 
    background-position: 50%; 
    position: relative; 
    z-index: 2; 
    min-height: 1px;
} 

.team_column { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .team_column  { 
    margin-right: 5.5%;
  } 

  .team_column_1_3  { 
    width: 29.6667%;
  } 
}     


.team_column_1 { 
    background-image: linear-gradient(180deg,#edf000 31%,#ffffff 31%); 
    border-radius: 20px 20px 20px 20px; 
    overflow: hidden; 
    padding-top: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px; 
    padding-left: 20px;
} 

.team_column_2 { 
    background-image: linear-gradient(180deg,#8300e9 31%,#ffffff 31%); 
    border-radius: 20px 20px 20px 20px; 
    overflow: hidden; 
    padding-top: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px; 
    padding-left: 20px;
} 

.team_row .team_column.et-last-child ,.team_row .team_column:last-child  { 
    margin-right: 0!important;
} 

@media (min-width: 981px){ 
  .team_row .team_column.et-last-child ,.team_row .team_column:last-child  { 
    margin-right: 0!important;
  } 
}     

.team_column.team_column_empty { 
    min-height: 1px;
} 

.team_column_4 { 
    background-image: linear-gradient(180deg,#00ffff 0%,#ffffff 0%); 
    border-radius: 20px 20px 20px 20px; 
    overflow: hidden; 
    padding-top: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px; 
    padding-left: 20px;
} 

.team_column_5 { 
    background-image: linear-gradient(180deg,#8300e9 0%,#ffffff 0%); 
    border-radius: 20px 20px 20px 20px; 
    overflow: hidden; 
    padding-top: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px; 
    padding-left: 20px;
    display: none;
} 

.team_column_7 { 
    background-image: linear-gradient(180deg,#00ffff 31%,#ffffff 31%); 
    border-radius: 20px 20px 20px 20px; 
    overflow: hidden; 
    padding-top: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px; 
    padding-left: 20px;
}
.team_column_8 { 
    background-image: linear-gradient(180deg,#ff6600 31%,#ffffff 31%); 
    border-radius: 20px 20px 20px 20px; 
    overflow: hidden; 
    padding-top: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px; 
    padding-left: 20px;
}

.team_module { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.team_module { 
    -webkit-animation-timing-function: linear; 
    animation-timing-function: linear; 
    -webkit-animation-duration: .2s; 
    animation-duration: .2s;
} 

.team_text { 
    word-wrap: break-word;
} 

.team_module.team_text_align_left { 
    text-align: left;
} 

@media (min-width: 981px){ 
  .team_column_1_3 .team_module  { 
    margin-bottom: 9.27%;
  } 
}     

.team_text_1 { 
    line-height: 0.8em; 
    font-size: 20px;
} 

.team_text_1.team_text { 
    color: #FFFFFF!important;
} 

ul { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

ul { 
    list-style: none;
} 

.entry-content ul { 
    list-style-type: disc; 
    padding: 0 0 23px 1em; 
    line-height: 26px;
} 

ul.team_social_media_follow { 
    list-style-type: none!important; 
    margin: 0 0 22px; 
    padding: 0;
} 

@media (min-width: 981px){ 
  .team_section div.team_row .team_column .team_module:last-child  { 
    margin-bottom: 0;
  } 
}     

.clearfix:after { 
    visibility: hidden; 
    display: block; 
    font-size: 0; 
    content: " "; 
    clear: both; 
    height: 0;
} 

.team_text_2 { 
    font-weight: 600; 
    font-size: 20px; 
    margin-bottom: 10px!important;
} 

.team_module.team_text_align_center { 
    text-align: center;
} 

.team_text_2.team_text { 
    color: #000000!important;
} 

.team_image { 
    margin-left: auto; 
    margin-right: auto; 
    line-height: 0;
} 

.team_image { 
    display: block;
} 

.team_image_0 { 
    width: 50%; 
    text-align: left; 
    margin-left: 0;
} 

.team_image_0.team_module { 
    margin-left: auto!important; 
    margin-right: auto!important;
} 

.team_text_4 { 
    font-size: 15px;
} 

.team_text_4.team_text { 
    color: #000000!important;
} 

.team_text_5 { 
    font-weight: 600; 
    font-size: 20px; 
    margin-bottom: 10px!important;
} 

.team_text_5.team_text { 
    color: #000000!important;
} 

.team_image_1 { 
    width: 50%; 
    text-align: left; 
    margin-left: 0;
} 

.team_image_1.team_module { 
    margin-left: auto!important; 
    margin-right: auto!important;
} 

.team_logo{
    width: 65%;
}

.team_text_7 { 
    font-size: 15px;
} 

.team_text_7.team_text { 
    color: #000000!important;
} 

.team_text_8 { 
    font-weight: 600; 
    font-size: 20px; 
    margin-bottom: 10px!important;
} 

.team_text_8.team_text { 
    color: #000000!important;
} 

.team_image_2 { 
    width: 50%; 
    text-align: left; 
    margin-left: 0;
} 

.team_image_2.team_module { 
    margin-left: auto!important; 
    margin-right: auto!important;
} 

.team_text_10 { 
    font-size: 15px;
} 

.team_text_10.team_text { 
    color: #000000!important;
} 

.team_text_11 { 
    font-weight: 600; 
    font-size: 20px; 
    margin-bottom: 10px!important;
} 

.team_text_11.team_text { 
    color: #000000!important;
} 

.team_image_3 { 
    width: 50%; 
    text-align: left; 
    margin-left: 0;
} 

.team_image_3.team_module { 
    margin-left: auto!important; 
    margin-right: auto!important;
} 

.team_text_13 { 
    font-size: 15px;
} 

.team_text_13.team_text { 
    color: #000000!important;
} 

.team_text_inner { 
    position: relative;
} 

.team_text > :last-child  { 
    padding-bottom: 0;
} 

li { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.team_social_media_follow li  { 
    display: inline-block; 
    margin-bottom: 8px; 
    position: relative;
} 

span { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.team_image .team_image_wrap  { 
    display: inline-block; 
    position: relative; 
    max-width: 100%;
} 

.team_image_0 .team_image_wrap  { 
    border-radius: 200px 200px 200px 200px; 
    overflow: hidden;
} 

.team_image_1 .team_image_wrap  { 
    border-radius: 200px 200px 200px 200px; 
    overflow: hidden;
} 

.team_image_2 .team_image_wrap  { 
    border-radius: 200px 200px 200px 200px; 
    overflow: hidden;
} 

.team_image_3 .team_image_wrap  { 
    border-radius: 200px 200px 200px 200px; 
    overflow: hidden;
} 

h2 { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

h2 { 
    color: #333; 
    padding-bottom: 10px; 
    line-height: 1em; 
    font-weight: 500;
} 

h2 { 
    font-size: 26px;
} 

.team_column_1_3 h2  { 
    font-size: 23px;
} 

.team_text_0 h2  { 
    font-weight: 600; 
    font-size: 32px; 
    color: #ff0000!important;
} 

p { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

p { 
    padding-bottom: 1em;
} 

p:not(.has-background):last-of-type { 
    padding-bottom: 0;
} 

a:where(:not(.wp-element-button)) { 
    text-decoration: underline;
} 

a { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

a { 
    color: #2ea3f2;
} 

a { 
    text-decoration: none;
} 

.team_with_border { 
    position: relative; 
    border: 0 solid #333;
} 

.team_social_media_follow li a  { 
    margin-right: 8px; 
    display: inline-block; 
    text-decoration: none; 
    text-align: center; 
    position: relative;
} 

.team_social_icon a.icon  { 
    vertical-align: middle;
} 

.team_social_media_follow_network_0 a.icon  { 
    background-color: #000000!important;
} 

.team_social_media_follow li a.icon  { 
    height: 32px; 
    width: 32px; 
    padding: 0; 
    -webkit-box-sizing: content-box; 
    box-sizing: content-box;
} 

.team_social_media_follow li a.icon  { 
    border-radius: 3px;
} 

ul.team_social_media_follow_0 a.icon  { 
    border-radius: 30px 30px 30px 30px;
} 

.team_social_media_follow .team_social_media_follow_network_0 .icon  { 
    height: 50px; 
    width: 50px;
} 

.et-social-twitter a.icon::before { 
    content: "\e094";
} 

.team_social_icon a.icon::before { 
    font-family: ETmodules; 
    font-style: normal; 
    font-weight: 400; 
    font-variant: normal; 
    text-transform: none; 
    line-height: 1; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 
    text-shadow: 0 0; 
    direction: ltr;
} 

.team_social_media_follow li a.icon::before { 
    height: 32px; 
    width: 32px; 
    font-size: 16px; 
    line-height: 32px; 
    display: block; 
    color: #fff; 
    -webkit-transition: color .3s; 
    transition: color .3s; 
    position: relative; 
    z-index: 10;
} 

.team_social_media_follow .team_social_media_follow_network_0 .icon::before, .team_social_media_follow .team_social_media_follow_network_1 .icon::before, .team_social_media_follow .team_social_media_follow_network_2 .icon::before, .team_social_media_follow .team_social_media_follow_network_3 .icon::before { 
    font-size: 25px; 
    line-height: 50px; 
    height: 50px; 
    width: 50px;
} 

a,a:hover { 
    text-decoration: none;
} 

.team_social_media_follow li a.icon:hover::before { 
    color: hsla(0,0%,100%,.7);
} 

.team_social_media_follow_network_1 a.icon  { 
    background-color: #3b5998!important;
} 

.team_social_media_follow .team_social_media_follow_network_1 .icon  { 
    height: 50px; 
    width: 50px;
} 

.et-social-facebook a.icon::before { 
    content: "\e093";
} 

.team_social_media_follow_network_2 a.icon  { 
    background-color: #25D366!important;
} 

.team_social_media_follow .team_social_media_follow_network_2 .icon  { 
    height: 50px; 
    width: 50px;
} 

.et-pb-social-fa-icon a.icon::before { 
    font-family: FontAwesome;
} 

.et-social-whatsapp a.icon::before { 
    content: "\F232";
} 

.team_social_media_follow_network_3 a.icon  { 
    background-color: #007bb6!important;
} 

.team_social_media_follow li:last-child a  { 
    margin-right: 0;
} 

.team_social_media_follow .team_social_media_follow_network_3 .icon  { 
    height: 50px; 
    width: 50px;
} 

.et-social-linkedin a.icon::before { 
    content: "\E09D";
} 

h6 { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

h6 { 
    color: #333; 
    padding-bottom: 10px; 
    line-height: 1em; 
    font-weight: 500;
} 

h6 { 
    font-size: 14px;
} 

.team_column_1_3 h6  { 
    font-size: 15px;
} 

.team_text_2 h6  { 
    font-weight: 700; 
    font-size: 24px; 
    color: #000000!important;
} 

h5 { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

h5 { 
    color: #333; 
    padding-bottom: 10px; 
    line-height: 1em; 
    font-weight: 500;
} 

h5 { 
    font-size: 16px;
} 

.team_column_1_3 h5  { 
    font-size: 16px;
} 

.team_text_3 h5  { 
    font-weight: 600; 
    font-size: 17px; 
    color: #000000!important; 
    text-align: center;
} 

img { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

img { 
    max-width: 100%; 
    height: auto;
} 

.team_image img  { 
    position: relative;
} 

.team_text_5 h6  { 
    font-weight: 700; 
    font-size: 24px; 
    color: #000000!important;
} 

.team_text_6 h5  { 
    font-weight: 600; 
    font-size: 17px; 
    color: #000000!important; 
    text-align: center;
} 

.team_text_8 h6  { 
    font-weight: 700; 
    font-size: 24px; 
    color: #000000!important;
} 

.team_text_9 h5  { 
    font-weight: 600; 
    font-size: 17px; 
    color: #000000!important; 
    text-align: center;
} 

.team_text_11 h6  { 
    font-weight: 700; 
    font-size: 24px; 
    color: #000000!important;
} 

.team_text_12 h5  { 
    font-weight: 600; 
    font-size: 17px; 
    color: #000000!important; 
    text-align: center;
} 

.team_text_1.team_text a  { 
    color: #FFFFFF!important;
} 

.team_social_media_follow_network_name { 
    display: none;
} 


/*mobile responsive*/
/* Mobile styles */
@media (max-width: 480px) {
    .team_with_background,
    .team_section,
    .team_section_0.team_section,
    .team_row {
       background-size: cover;
       background-position: 50%;
       background-repeat: no-repeat;
       position: relative;
     }
   
    .team_section {
       background-color: #fff;
     }
   
    .team_section_0.team_section {
       background-color: rgba(0, 0, 0, 0.25)!important;
     }
   
     div.team_section.team_section_0 {
       background-blend-mode: multiply;
       background-image: url(https://appertaxtech.co.zw/react/wp-content/uploads/2024/04/download_1.jpeg.jpg)!important;
     }
   
    .team_row {
       width: 100%;
       max-width: none;
       margin: auto;
       position: relative;
     }
   }
   
   /* Tablet styles */
   @media (min-width: 481px) and (max-width: 980px) {
    .team_with_background,
    .team_section,
    .team_section_0.team_section,
    .team_row {
       background-size: cover;
       background-position: 50%;
       background-repeat: no-repeat;
       position: relative;
     }
   
    .team_section {
       background-color: #fff;
     }
   
    .team_section_0.team_section {
       background-color: rgba(0, 0, 0, 0.25)!important;
     }
   
     div.team_section.team_section_0 {
       background-blend-mode: multiply;
       background-image: url(https://appertaxtech.co.zw/react/wp-content/uploads/2024/04/download_1.jpeg.jpg)!important;
     }
   
    .team_row {
       width: 80%;
       max-width: 1080px;
       margin: auto;
       position: relative;
     }
   }
   
   /* Desktop styles */
   @media (min-width: 981px) {
    .team_section {
       padding: 4% 0;
     }
   }
   
   /* Large desktop styles */
   @media (min-width: 1350px) {
    .team_section {
       padding: 54px 0;
     }
   }

   /*.team_module {
    display: flex;
    flex-direction: column;
  }*/
  
  .team_text_inner {
    display: flex;
    flex-direction: column;
  }
  .team_nav_button {
    padding: 5px 15px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    width: fit-content;
    font-size: 18px;
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
  }
  
  .team_nav_button_border {
    border: 1px solid rgb(255, 255, 255);
    border-radius: 20px;
  }
  
  .team_button_text {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }

  @media screen and (max-width: 600px) {
    .team_row{
        width: 80%;
    }

    .team_text_10 {
        padding-top: 20px;
    }

    .team_text_7 {
        padding-top: 20px;
    }
    
    .team_text_4 {
        padding-top: 20px;
    }

    .team_column_2 { 
        background-image: linear-gradient(180deg,#8300e9 30%,#ffffff 30%); 
    }

    .team_text_13 {
        padding-top: 20px;
    }

    .team_logo{
        padding-bottom: 30px;
        width: 55%;
    }

    .team_section{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .team_column_1{
        margin-bottom: 30px;
    }
    .team_column_2{
        margin-bottom: 30px;
    }
    .team_column_4{
        margin-bottom: 30px;
    }
    .team_module.team_text_1.team_text_align_left {
        padding-bottom: 20px; /* Adjust the padding for mobile devices */
      }

  }
