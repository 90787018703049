@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap'); 
  body {  
    font-size:100%;
    background:transparent;
    line-height:1.7em;
    font-family:Open Sans,Arial,sans-serif;
    color:#666;
    font-weight:500;
  }  
* { 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
} 

div { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    margin: 0; 
} 

body { 
    padding-top: 0px; 
    padding-right: 0px; 
    padding-bottom: 0px; 
    padding-left: 0px;
} 

body { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    line-height: 1;
} 

body { 
    font-family: Open Sans,Arial,sans-serif; 
    font-size: 14px; 
    color: #666; 
    background-color: #fff; 
    line-height: 1.7em; 
    font-weight: 500; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
} 

/*@media all{ 
  html { 
    --wp-admin--admin-bar--height: 32px; 
    scroll-padding-top: var(--wp-admin--admin-bar--height);
  } 
}     

html { 
    margin-top: 32px !important;
} */

html { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.ov_with_background { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.ov_section { 
    position: relative; 
    background-color: #fff; 
    background-position: 50%; 
    background-size: cover;
} 

@media (min-width: 981px){ 
  .ov_section { 
    padding: 4% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .ov_section { 
    padding: 54px 0;
  } 
}     

.ov_section_0.ov_section { 
    padding-top: 80px; 
    padding-bottom: 80px; 
    background-color: rgba(0,0,0,0.45)!important;
} 

div.ov_section.ov_section_0 { 
    background-blend-mode: multiply; 
    background-image: url(C:\Users\Dopor\Desktop\sportstake\src\images\sportstake5.jpg)!important;
} 

.ov_row { 
    width: 80%; 
    max-width: 1080px; 
    margin: auto; 
    position: relative;
}

.ov_logo{
  width: 65%;
}

.ov_row { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .ov_row { 
    padding: 2% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .ov_row { 
    padding: 27px 0;
  } 
}     

.ov_row:after,.ov_row_inner:after { 
    content: ""; 
    display: block; 
    clear: both; 
    visibility: hidden; 
    line-height: 0; 
    height: 0; 
    width: 0;
} 

.ov_css_mix_blend_mode_passthrough { 
    mix-blend-mode: unset!important;
} 

.ov_column { 
    float: left; 
    background-size: cover; 
    background-position: 50%; 
    position: relative; 
    z-index: 2; 
    min-height: 1px;
} 

.ov_column { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .ov_column  { 
    margin-right: 5.5%;
  } 

  .ov_column_1_3  { 
    width: 29.6667%;
  } 
}     

.ov_column_1 { 
    padding-right: 30px; 
    padding-left: 30px;
} 

@media (min-width: 981px){ 
  .ov_column_2_3  { 
    width: 64.833%;
  } 
}     

.ov_row .ov_column.et-last-child ,.ov_row .ov_column:last-child  { 
    margin-right: 0!important;
} 

@media (min-width: 981px){ 
  .ov_row .ov_column.et-last-child ,.ov_row .ov_column:last-child  { 
    margin-right: 0!important;
  } 
}     

.ov_module { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.ov_module { 
    -webkit-animation-timing-function: linear; 
    animation-timing-function: linear; 
    -webkit-animation-duration: .2s; 
    animation-duration: .2s;
} 

.ov_text { 
    word-wrap: break-word;
} 

.ov_text_0 { 
    font-weight: 600; 
    font-size: 40px;
} 

.ov_module.ov_text_align_left { 
    text-align: left;
} 

.ov_text_0.ov_text { 
    color: #ff0000!important;
} 

@media (min-width: 981px){ 
  .ov_column_1_3 .ov_module  { 
    margin-bottom: 9.27%;
  } 
}     

.ov_text_1 { 
    line-height: 0.8em; 
    font-size: 20px;
} 

.ov_text_1.ov_text { 
    color: #FFFFFF!important;
} 

@media (min-width: 981px){ 
  .ov_section div.ov_row .ov_column .ov_module:last-child  { 
    margin-bottom: 0;
  } 
}     

.ov_text_2 { 
    font-weight: 700; 
    font-size: 32px;
} 

.ov_module.ov_text_align_center { 
    text-align: center;
} 

.ov_text_2.ov_text { 
    color: #FFFFFF!important;
} 

@media (min-width: 981px){ 
  .ov_column_2_3 .ov_module  { 
    margin-bottom: 4.242%;
  } 
}     

.ov_text_3 { 
    line-height: 1.5em; 
    font-size: 24px;
} 

.ov_text_3.ov_text { 
    color: #FFFFFF!important;
} 

.ov_text_inner { 
    position: relative;
} 

.ov_text > :last-child  { 
    padding-bottom: 0;
} 

p { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

p { 
    padding-bottom: 1em;
} 

p:not(.has-background):last-of-type { 
    padding-bottom: 0;
} 

a:where(:not(.wp-element-button)) { 
    text-decoration: underline;
} 

a { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

a { 
    color: #2ea3f2;
} 

a { 
    text-decoration: none;
} 

.ov_text_1.ov_text a  { 
    color: #FFFFFF!important;
} 

a,a:hover { 
    text-decoration: none;
} 

.ov_module {
    display: flex;
    flex-direction: column;
  }
  
  .ov_text_inner {
    display: flex;
    flex-direction: column;
  }
  .ov_nav_button {
    padding: 5px 15px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    width: fit-content;
    font-size: 18px;
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
  }
  
  .ov_nav_button_border {
    border: 1px solid rgb(255, 255, 255) !important;
    border-radius: 20px;
  }
  
  .ov_button_text {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }

/* Header Font Size, Add the following media query to adjust the font size for mobile devices */
@media screen and (max-width: 600px) {
  .overview_0-5-21-22 h2 {
         font-size: 30px;
         line-height: 1em;
     }
  .ov_module.ov_text_align_center {
    padding-top: 15px;
    padding-bottom: 0px; /* Add padding to the div */
  }

  .ov_row { 
    width: 90%; 
    max-width: 1080px; 
    margin: auto; 
    position: relative;
  }

  .ov_logo{
    padding-bottom: 30px;
  }

  .ov_column_1 {
    padding: 0 0; /* Adjust the padding for mobile devices */
  }

  .overview_0-5-24-25 {
    line-height: 2em;
    font-size: 15px; /* Set the font size to 14px for mobile devices */
  }

}