@font-face { 
  font-family:ETmodules;
  font-display:block;
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.eot);
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.eot?#iefix) format("embedded-opentype"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.woff) format("woff"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.ttf) format("truetype"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.svg#ETmodules) format("svg");
  font-weight:400;
  font-style:normal;
} 
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900,100i,200i,300i,400i,500i,600i,700i,800i,900i&display=swap'); 
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap'); 
  /*body {  
    font-size:100%;
    background:transparent;
    line-height:1.7em;
    font-family:Open Sans,Arial,sans-serif;
    color:#666;
    font-weight:500;
  }  
* { 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
} 

div { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    margin: 0; 
} 

body { 
    padding-top: 0px; 
    padding-right: 0px; 
    padding-bottom: 0px; 
    padding-left: 0px;
} 

body { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    line-height: 1;
} 

body { 
    font-family: Open Sans,Arial,sans-serif; 
    font-size: 14px; 
    color: #666; 
    background-color: #fff; 
    line-height: 1.7em; 
    font-weight: 500; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
} 

@media all{ 
  html { 
    --wp-admin--admin-bar--height: 0px; 
    scroll-padding-top: var(--wp-admin--admin-bar--height);
  } 
}     

/*html { 
    margin-top: 32px !important;
} */

.fo_ar_module {
  display: flex;
  flex-direction: column;
}

.fo_ar_text_inner {
  display: flex;
  flex-direction: column;
}
.fo_ar_logo{
    width: 65%;
}
.fa_nav_button {
  padding: 5px 15px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  width: fit-content;
  font-size: 18px;
  color: rgb(0, 0, 0);
  margin-bottom: 5px;
}

.fa_nav_button_border {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 20px;
}

.fa_button_text {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

html { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.fo_ar_with_background { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.fo_ar_section { 
    position: relative; 
    background-color: #fff; 
    background-position: 50%; 
    background-size: cover;
} 

@media (min-width: 981px){ 
  .fo_ar_section { 
    padding: 4% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .fo_ar_section { 
    padding: 54px 0;
  } 
}     

.fo_ar_section_0.fo_ar_section { 
    padding-top: 50px; 
    padding-bottom: 50px; 
    background-color: rgba(237,240,0,0.94)!important;
} 

div.fo_ar_section.fo_ar_section_0 { 
    background-blend-mode: overlay; 
    background-image: url(C:\Users\Dopor\Desktop\sportstake\src\images\sportstake8.jpg)!important;
} 

.fo_ar_row { 
    width: 80%; 
    max-width: 1080px; 
    margin: auto; 
    position: relative;
} 

.fo_ar_row { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .fo_ar_row { 
    padding: 2% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .fo_ar_row { 
    padding: 27px 0;
  } 
}     

.fo_ar_row:after,.fo_ar_row_inner:after { 
    content: ""; 
    display: block; 
    clear: both; 
    visibility: hidden; 
    line-height: 0; 
    height: 0; 
    width: 0;
} 

.fo_ar_css_mix_blend_mode_passthrough { 
    mix-blend-mode: unset!important;
} 

.fo_ar_column { 
    float: left; 
    background-size: cover; 
    background-position: 50%; 
    position: relative; 
    z-index: 2; 
    min-height: 1px;
} 

.fo_ar_column { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .fo_ar_column  { 
    margin-right: 5.5%;
  } 

  .fo_ar_column_1_3  { 
    width: 29.6667%;
  } 

  .fo_ar_column_2_3  { 
    width: 64.833%;
  } 
}     

.fo_ar_row .fo_ar_column.et-last-child ,.fo_ar_row .fo_ar_column:last-child  { 
    margin-right: 0!important;
} 

@media (min-width: 981px){ 
  .fo_ar_row .fo_ar_column.et-last-child ,.fo_ar_row .fo_ar_column:last-child  { 
    margin-right: 0!important;
  } 

  .fo_ar_column_1_2  { 
    width: 47.25%;
  } 
}     

.fo_ar_module { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.fo_ar_module { 
    -webkit-animation-timing-function: linear; 
    animation-timing-function: linear; 
    -webkit-animation-duration: .2s; 
    animation-duration: .2s;
} 

.fo_ar_text { 
    word-wrap: break-word;
} 

.fo_ar_module.fo_ar_text_align_left { 
    text-align: left;
} 

@media (min-width: 981px){ 
  .fo_ar_column_1_3 .fo_ar_module  { 
    margin-bottom: 9.27%;
  } 

  div.fo_ar_row .fo_ar_column .fo_ar_module:last-child  { 
    margin-bottom: 0;
  } 
}     

.fo_ar_with_border { 
    position: relative; 
    border: 0 solid #333;
} 

.fo_ar_text_1 { 
    border-bottom-width: 2px; 
    border-bottom-color: #000000; 
    margin-bottom: 0px!important;
} 

@media (min-width: 981px){ 
  .fo_ar_column_2_3 .fo_ar_module  { 
    margin-bottom: 4.242%;
  } 
}     

.fo_ar_text_2 { 
    line-height: 0.8em; 
    font-size: 20px;
} 

.fo_ar_text_2.fo_ar_text { 
    color: #FFFFFF!important;
} 

.fo_ar_image { 
    margin-left: auto; 
    margin-right: auto; 
    line-height: 0;
} 

.fo_ar_image { 
    display: block;
} 

.fo_ar_image_0 { 
    width: 40%; 
    text-align: center;
} 

.fo_ar_toggle { 
    border: 1px solid #d9d9d9;
} 

.fo_ar_toggle_close { 
    background-color: #f4f4f4; 
    padding: 20px;
} 

.fo_ar_toggle_0.fo_ar_toggle { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    color: #000000!important; 
    padding-top: 0px!important; 
    padding-right: 0px!important; 
    padding-bottom: 0px!important;
} 

.fo_ar_module.fo_ar_toggle_0.fo_ar_toggle { 
    border-width: 0px;
} 

.fo_ar_toggle_0.fo_ar_toggle.fo_ar_toggle_close { 
    background-color: RGBA(255,255,255,0);
} 

.fo_ar_image_1 { 
    width: 40%; 
    text-align: center;
} 

.fo_ar_toggle_1.fo_ar_toggle { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    color: #000000!important; 
    padding-top: 0px!important; 
    padding-right: 0px!important; 
    padding-bottom: 0px!important;
} 

.fo_ar_module.fo_ar_toggle_1.fo_ar_toggle { 
    border-width: 0px;
} 

.fo_ar_toggle_1.fo_ar_toggle.fo_ar_toggle_close { 
    background-color: RGBA(255,255,255,0);
} 

.fo_ar_image_2 { 
    width: 40%; 
    text-align: center;
} 

.fo_ar_toggle_2.fo_ar_toggle { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    color: #000000!important; 
    padding-top: 0px!important; 
    padding-right: 0px!important; 
    padding-bottom: 0px!important;
} 

.fo_ar_module.fo_ar_toggle_2.fo_ar_toggle { 
    border-width: 0px;
} 

.fo_ar_toggle_2.fo_ar_toggle.fo_ar_toggle_close { 
    background-color: RGBA(255,255,255,0);
} 

.fo_ar_image_3 { 
    width: 40%; 
    text-align: center;
} 

.fo_ar_toggle_3.fo_ar_toggle { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    color: #000000!important; 
    padding-top: 0px!important; 
    padding-right: 0px!important; 
    padding-bottom: 0px!important;
} 

.fo_ar_module.fo_ar_toggle_3.fo_ar_toggle { 
    border-width: 0px;
} 

.fo_ar_toggle_3.fo_ar_toggle.fo_ar_toggle_close { 
    background-color: RGBA(255,255,255,0);
} 

.fo_ar_image_4 { 
    width: 40%; 
    text-align: center;
} 

.fo_ar_toggle_4.fo_ar_toggle { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    color: #000000!important; 
    padding-top: 0px!important; 
    padding-right: 0px!important; 
    padding-bottom: 0px!important;
} 

.fo_ar_module.fo_ar_toggle_4.fo_ar_toggle { 
    border-width: 0px;
} 

.fo_ar_toggle_4.fo_ar_toggle.fo_ar_toggle_close { 
    background-color: RGBA(255,255,255,0);
} 

.fo_ar_image_5 { 
    width: 25%; 
    text-align: center;
} 

@media (min-width: 981px){ 
  .fo_ar_column_1_2 .fo_ar_module  { 
    margin-bottom: 5.82%;
  } 
}     

.fo_ar_toggle_5.fo_ar_toggle { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    color: #000000!important; 
    padding-top: 0px!important; 
    padding-right: 0px!important; 
    padding-bottom: 0px!important;
} 

.fo_ar_module.fo_ar_toggle_5.fo_ar_toggle { 
    border-width: 0px;
} 

.fo_ar_toggle_5.fo_ar_toggle.fo_ar_toggle_close { 
    background-color: RGBA(255,255,255,0);
} 

.fo_ar_image_6 { 
    width: 25%; 
    text-align: center;
} 

.fo_ar_toggle_6.fo_ar_toggle { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    color: #000000!important; 
    padding-top: 0px!important; 
    padding-right: 0px!important; 
    padding-bottom: 0px!important;
} 

.fo_ar_module.fo_ar_toggle_6.fo_ar_toggle { 
    border-width: 0px;
} 

.fo_ar_toggle_6.fo_ar_toggle.fo_ar_toggle_close { 
    background-color: RGBA(255,255,255,0);
} 

.fo_ar_text_inner { 
    position: relative;
} 

.fo_ar_text > :last-child  { 
    padding-bottom: 0;
} 

span { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.fo_ar_with_border .fo_ar_image_wrap  { 
    border: 0 solid #333;
} 

.fo_ar_image .fo_ar_image_wrap  { 
    display: inline-block; 
    position: relative; 
    max-width: 100%;
} 

.fo_ar_image_0 .fo_ar_image_wrap  { 
    border-radius: 100px 100px 100px 100px; 
    overflow: hidden; 
    border-width: 7px; 
    border-color: #000000;
} 

h5 { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

h5 { 
    color: #333; 
    padding-bottom: 10px; 
    line-height: 1em; 
    font-weight: 500;
} 

h5 { 
    font-size: 16px;
} 

.fo_ar_toggle_title { 
    word-wrap: break-word;
} 

.fo_ar_column_1_3 h5  { 
    font-size: 16px;
} 

.fo_ar_toggle .fo_ar_toggle_title ,.fo_ar_toggle h5.fo_ar_toggle_title  { 
    cursor: pointer; 
    position: relative; 
    padding: 0 50px 0 0;
} 

.fo_ar_toggle_close .fo_ar_toggle_title ,.fo_ar_toggle_close h5.fo_ar_toggle_title  { 
    color: #666;
} 

.fo_ar_toggle_0.fo_ar_toggle h5  { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 700; 
    color: #000000!important; 
    text-align: center;
} 

#et-info-email:before,#et-info-phone:before,#et_search_icon:before,.comment-reply-link:after, .et-cart-info span::before,.et-pb-arrow-next:before,.et-pb-arrow-prev:before, .et-social-icon a::before, .et_audio_container .mejs-playpause-button button::before, .et_audio_container .mejs-volume-button button::before,.et_overlay:before, .et_password_protected_form .et_submit_button::after,.fo_ar_button:after,.fo_ar_contact_reset:after,.fo_ar_contact_submit:after,.fo_ar_font_icon:before,.fo_ar_newsletter_button:after,.fo_ar_pricing_table_button:after,.fo_ar_promo_button:after,.fo_ar_testimonial:before,.fo_ar_toggle_title:before, .form-submit .fo_ar_button::after,.mobile_menu_bar:before,a.fo_ar_more_button:after { 
    font-family: ETmodules!important; 
    font-style: normal; 
    font-weight: 400; 
    -webkit-font-feature-settings: normal; 
    font-feature-settings: normal; 
    font-variant: normal; 
    text-transform: none; 
    line-height: 1; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 
    text-shadow: 0 0; 
    direction: ltr;
} 

.fo_ar_toggle_title:before { 
    content: "\E050"; 
    position: absolute; 
    right: 0; 
    font-size: 16px; 
    color: #ccc; 
    top: 50%; 
    margin-top: -.5em;
} 

.fo_ar_toggle .fo_ar_toggle_title::before { 
    right: 0; 
    left: auto;
} 

.fo_ar_toggle_0.fo_ar_toggle_open .fo_ar_toggle_title::before, .fo_ar_toggle_0.fo_ar_toggle_close .fo_ar_toggle_title::before, .fo_ar_toggle_1.fo_ar_toggle_open .fo_ar_toggle_title::before, .fo_ar_toggle_1.fo_ar_toggle_close .fo_ar_toggle_title::before, .fo_ar_toggle_2.fo_ar_toggle_open .fo_ar_toggle_title::before, .fo_ar_toggle_2.fo_ar_toggle_close .fo_ar_toggle_title::before, .fo_ar_toggle_3.fo_ar_toggle_open .fo_ar_toggle_title::before, .fo_ar_toggle_3.fo_ar_toggle_close .fo_ar_toggle_title::before, .fo_ar_toggle_4.fo_ar_toggle_open .fo_ar_toggle_title::before, .fo_ar_toggle_4.fo_ar_toggle_close .fo_ar_toggle_title::before, .fo_ar_toggle_5.fo_ar_toggle_open .fo_ar_toggle_title::before, .fo_ar_toggle_5.fo_ar_toggle_close .fo_ar_toggle_title::before, .fo_ar_toggle_6.fo_ar_toggle_open .fo_ar_toggle_title::before, .fo_ar_toggle_6.fo_ar_toggle_close .fo_ar_toggle_title::before { 
    color: #000000;
} 

.fo_ar_toggle_content { 
    word-wrap: break-word;
} 

.fo_ar_toggle_content { 
    padding-top: 20px; 
    position: relative;
} 

.fo_ar_toggle_close .fo_ar_toggle_content  { 
    display: none;
} 

.clearfix:after { 
    visibility: hidden; 
    display: block; 
    font-size: 0; 
    content: " "; 
    clear: both; 
    height: 0;
} 

.fo_ar_image_1 .fo_ar_image_wrap  { 
    border-radius: 100px 100px 100px 100px; 
    overflow: hidden; 
    border-width: 7px; 
    border-color: #000000;
} 

.fo_ar_toggle_1.fo_ar_toggle h5  { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 700; 
    color: #000000!important; 
    text-align: center;
} 

#et-info-email::before, #et-info-phone::before, #et_search_icon::before, .comment-reply-link::after, .et-cart-info span::before, .et-pb-arrow-next::before, .et-pb-arrow-prev::before, .et-social-icon a::before, .et_audio_container .mejs-playpause-button button::before, .et_audio_container .mejs-volume-button button::before, .et_overlay::before, .et_password_protected_form .et_submit_button::after, .fo_ar_button::after, .fo_ar_contact_reset::after, .fo_ar_contact_submit::after, .fo_ar_font_icon::before, .fo_ar_newsletter_button::after, .fo_ar_pricing_table_button::after, .fo_ar_promo_button::after, .fo_ar_testimonial::before, .fo_ar_toggle_title::before, .form-submit .fo_ar_button::after, .mobile_menu_bar::before, a.fo_ar_more_button::after { 
    font-family: ETmodules!important; 
    font-style: normal; 
    font-weight: 400; 
    -webkit-font-feature-settings: normal; 
    font-feature-settings: normal; 
    font-variant: normal; 
    text-transform: none; 
    line-height: 1; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 
    text-shadow: 0 0; 
    direction: ltr;
} 

.fo_ar_image_2 .fo_ar_image_wrap  { 
    border-radius: 100px 100px 100px 100px; 
    overflow: hidden; 
    border-width: 7px; 
    border-color: #000000;
} 

.fo_ar_toggle_2.fo_ar_toggle h5  { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 700; 
    color: #000000!important; 
    text-align: center;
} 

.fo_ar_image_3 .fo_ar_image_wrap  { 
    border-radius: 100px 100px 100px 100px; 
    overflow: hidden; 
    border-width: 7px; 
    border-color: #000000;
} 

.fo_ar_toggle_3.fo_ar_toggle h5  { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 700; 
    color: #000000!important; 
    text-align: center;
} 

.fo_ar_image_4 .fo_ar_image_wrap  { 
    border-radius: 100px 100px 100px 100px; 
    overflow: hidden; 
    border-width: 7px; 
    border-color: #000000;
} 

.fo_ar_toggle_4.fo_ar_toggle h5  { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 700; 
    color: #000000!important; 
    text-align: center;
} 

.fo_ar_image_5 .fo_ar_image_wrap  { 
    border-radius: 100px 100px 100px 100px; 
    overflow: hidden; 
    border-width: 7px; 
    border-color: #000000;
} 

.fo_ar_toggle_5.fo_ar_toggle h5  { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 700; 
    color: #000000!important; 
    text-align: center;
} 

.fo_ar_image_6 .fo_ar_image_wrap  { 
    border-radius: 100px 100px 100px 100px; 
    overflow: hidden; 
    border-width: 7px; 
    border-color: #000000;
} 

.fo_ar_toggle_6.fo_ar_toggle h5  { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 700; 
    color: #000000!important; 
    text-align: center;
} 

h2 { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

h2 { 
    color: #333; 
    padding-bottom: 10px; 
    line-height: 1em; 
    font-weight: 500;
} 

h2 { 
    font-size: 26px;
} 

.fo_ar_column_1_3 h2  { 
    font-size: 23px;
} 

.fo_ar_text_0 h2  { 
    font-weight: 600; 
    font-size: 32px; 
    color: #ff0000!important;
} 

.fo_ar_text_1 h2  { 
    font-weight: 600; 
    font-size: 32px; 
    color: #000000!important; 
    text-align: center;
} 

p { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

p { 
    padding-bottom: 1em;
} 

p:not(.has-background):last-of-type { 
    padding-bottom: 0;
} 

img { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

img { 
    max-width: 100%; 
    height: auto;
} 

.fo_ar_image img  { 
    position: relative;
} 

.fo_ar_toggle_content p:last-of-type  { 
    padding-bottom: 0;
} 

a:where(:not(.wp-element-button)) { 
    text-decoration: underline;
} 

a { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

a { 
    color: #2ea3f2;
} 

a { 
    text-decoration: none;
} 

.fo_ar_text_2.fo_ar_text a  { 
    color: #000000!important;
} 

a,a:hover { 
    text-decoration: none;
} 

/* Add the following media query to hide the element on mobile devices */
@media screen and (max-width: 600px) {
    .fo_ar_column_2_3 {
       display: none;
     }

      .fo_ar_text_1_new h2{
        display: block;
        font-weight: 600; 
        font-size: 32px; 
        color: #000000!important; 
        text-align: center;
        padding-bottom: 30px;
    }

    .fo_ar_image_5 { 
        width: 40%; 
        text-align: center;
    } 

    .fo_ar_image_6 { 
        width: 40%; 
        text-align: center;
    } 

    .fo_ar_toggle_5 .fo_ar_toggle h5  { 
        font-family: 'Montserrat', Helvetica, Arial, Lucida, sans-serif;
        font-weight: 700;
        color: #000000 !important;
        text-align: center;
        font-size: 16px !important;
    } 

    h5{
        font-size: 16px;
    }
    .fo_ar_module.fo_ar_text_2.fo_ar_text_align_left{
        padding-bottom: 20px; /* Adjust the padding for mobile devices */
      }
    .fo_ar_logo{
        padding-bottom: 30px;
        width: 55%;
    }
}

/* Hide the element on desktop devices */
@media screen and (min-width: 601px) {
    .fo_ar_text_1_new h2{
        display: none;
    }
    .fo_ar_toggle_title2 {
        font-size: 16px !important;
    }
    .fo_ar_toggle_6.fo_ar_toggle h5 {
        font-size: 16px !important;
    }
}

