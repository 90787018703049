@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900,100i,200i,300i,400i,500i,600i,700i,800i,900i&display=swap'); 
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap'); 
  body {  
    font-size:100%;
    background:transparent;
    /*background-image:url(https://appertaxtech.co.zw/react/wp-content/uploads/2024/03/sportstake3-scaled.jpg)!important;
    */line-height:1.7em;
    font-family:Open Sans,Arial,sans-serif;
    color:#666;
    font-weight:500;
  }  
* { 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
} 

div { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    margin: 0; 
} 

body { 
    padding-top: 0px; 
    padding-right: 0px; 
    padding-bottom: 0px; 
    padding-left: 0px;
} 

body { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    line-height: 1;
} 

body { 
    font-family: Open Sans,Arial,sans-serif; 
    font-size: 14px; 
    color: #666; 
    background-color: #fff; 
    line-height: 1.7em; 
    font-weight: 500; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
} 

/*@media all{ 
  html { 
    --wp-admin--admin-bar--height: 32px; 
    scroll-padding-top: var(--wp-admin--admin-bar--height);
  } 
}     

html { 
    margin-top: 32px !important;
} */

html { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.vi_mi_row { 
    width: 80%; 
    max-width: 1080px; 
    margin: auto; 
    position: relative;
} 

.vi_mi_row { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .vi_mi_row { 
    padding: 2% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .vi_mi_row { 
    padding: 27px 0;
  } 
}     

.vi_mi_row_0 { 
    background-color: rgba(237,240,0,0.85);
} 

.vi_mi_row_0 { 
    width: 100%; 
    max-width: 100%;
} 

.vi_mi_row_0.vi_mi_row { 
    padding-top: 70px; 
    padding-right: 10%; 
    padding-left: 10%;
} 

.vi_mi_row:after,.vi_mi_row_inner:after { 
    content: ""; 
    display: block; 
    clear: both; 
    visibility: hidden; 
    line-height: 0; 
    height: 0; 
    width: 0;
} 

.vi_mi_css_mix_blend_mode_passthrough { 
    mix-blend-mode: unset!important;
} 

.vi_mi_column { 
    float: left; 
    background-size: cover; 
    background-position: 50%; 
    position: relative; 
    z-index: 2; 
    min-height: 1px;
} 

.vi_mi_column { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .vi_mi_column  { 
    margin-right: 5.5%;
  } 

  .vi_mi_column_1_4  { 
    width: 20.875%;
  } 

  .vi_mi_gutters2 .vi_mi_column ,.vi_mi_gutters2.vi_mi_row .vi_mi_column  { 
    margin-right: 3%;
  } 

  .vi_mi_gutters2 .vi_mi_column_1_4 ,.vi_mi_gutters2.vi_mi_row .vi_mi_column_1_4  { 
    width: 22.75%;
  } 

  .vi_mi_column_3_4  { 
    width: 73.625%;
  } 

  .vi_mi_gutters2 .vi_mi_column_3_4 ,.vi_mi_gutters2.vi_mi_row .vi_mi_column_3_4  { 
    width: 74.25%;
  } 
}     

.vi_mi_column.et-last-child ,.vi_mi_column:last-child  { 
    margin-right: 0!important;
} 

@media (min-width: 981px){ 
  .vi_mi_column.et-last-child ,.vi_mi_column:last-child  { 
    margin-right: 0!important;
  } 
}     

.vi_mi_module { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.vi_mi_module { 
    -webkit-animation-timing-function: linear; 
    animation-timing-function: linear; 
    -webkit-animation-duration: .2s; 
    animation-duration: .2s;
} 

.vi_mi_text { 
    word-wrap: break-word;
} 

.vi_mi_module.vi_mi_text_align_left { 
    text-align: left;
} 

@media (min-width: 981px){ 
  .vi_mi_column_1_4 .vi_mi_module  { 
    margin-bottom: 13.174%;
  } 

  .vi_mi_gutters2 .vi_mi_column_1_4 .vi_mi_module ,.vi_mi_gutters2.vi_mi_row .vi_mi_column_1_4 .vi_mi_module  { 
    margin-bottom: 6.593%;
  } 
}     

.vi_mi_text_1 { 
    line-height: 0.8em; 
    font-size: 20px;
} 

.vi_mi_text_1.vi_mi_text { 
    color: #FFFFFF!important;
} 

@media (min-width: 981px){ 
  .vi_mi_column .vi_mi_module:last-child  { 
    margin-bottom: 0;
  } 
}     

.vi_mi_text_2 { 
    margin-bottom: 5px!important;
} 

@media (min-width: 981px){ 
  .vi_mi_column_3_4 .vi_mi_module  { 
    margin-bottom: 3.735%;
  } 

  .vi_mi_gutters2 .vi_mi_column_3_4 .vi_mi_module ,.vi_mi_gutters2.vi_mi_row .vi_mi_column_3_4 .vi_mi_module  { 
    margin-bottom: 2.02%;
  } 
}     

.vi_mi_text_3 { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    margin-bottom: 10px!important;
} 

.vi_mi_text_3.vi_mi_text { 
    color: #000000!important;
} 

.vi_mi_space { 
    -webkit-box-sizing: content-box; 
    box-sizing: content-box; 
    height: 23px;
} 

.vi_mi_divider { 
    margin: 0 0 30px; 
    position: relative;
} 

.vi_mi_divider_0 { 
    width: 78%;
} 

.vi_mi_divider_0.vi_mi_module { 
    margin-left: auto!important; 
    margin-right: auto!important;
} 

.vi_mi_divider:before { 
    content: ""; 
    width: 100%; 
    height: 1px; 
    border-top: 1px solid rgba(0,0,0,.1); 
    position: absolute; 
    left: 0; 
    top: 0; 
    z-index: 10;
} 

.vi_mi_divider_0:before { 
    border-top-color: #000000; 
    border-top-width: 2px;
} 

.vi_mi_divider:after,.vi_mi_space:after { 
    content: ""; 
    display: table;
} 

.vi_mi_text_inner { 
    position: relative;
} 

.vi_mi_text > :last-child  { 
    padding-bottom: 0;
} 

.vi_mi_divider_internal { 
    display: inline-block; 
    width: 100%;
} 

.vi_mi_logo{
  width: 75%;
}

h2 { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

h2 { 
    color: #333; 
    padding-bottom: 10px; 
    line-height: 1em; 
    font-weight: 500;
} 

h2 { 
    font-size: 26px;
} 

.vi_mi_column_1_4 h2  { 
    font-size: 23px;
} 

.vi_mi_text_0 h2  { 
    font-weight: 600; 
    font-size: 30px; 
    color: #ff0000!important;
} 

p { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

p { 
    padding-bottom: 1em;
} 

p:not(.has-background):last-of-type { 
    padding-bottom: 0;
} 

.vi_mi_text_2 h2  { 
    font-size: 25px; 
    color: #000000!important; 
    text-align: left;
} 

a:where(:not(.wp-element-button)) { 
    text-decoration: underline;
} 

a { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

a { 
    color: #2ea3f2;
} 

a { 
    text-decoration: none;
} 

.vi_mi_text_1.vi_mi_text a  { 
    color: #000000!important;
} 

a,a:hover { 
    text-decoration: none;
} 

.ov_module {
  display: flex;
  flex-direction: column;
}

.vi_mi_text_inner {
  display: flex;
  flex-direction: column;
}
.vi_mi_nav_button {
  padding: 5px 15px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  width: fit-content;
  font-size: 18px;
  color: rgb(0, 0, 0);
  margin-bottom: 5px;
}

.vi_mi_nav_button_border {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 20px;
}

.vi_mi_button_text {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap'); 
  .vi_mi_2_section {  
    font-size:100%;
    background:transparent;
    background-image:url(https://appertaxtech.co.zw/react/wp-content/uploads/2024/03/sportstake3-scaled.jpg)!important;
    line-height:1.7em;
    font-family:Open Sans,Arial,sans-serif;
    color:#666;
    font-weight:500;
  }  
* { 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
} 

div { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    margin: 0; 
} 

body { 
    padding-top: 0px; 
    padding-right: 0px; 
    padding-bottom: 0px; 
    padding-left: 0px;
} 

body { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    line-height: 1;
} 

body { 
    font-family: Open Sans,Arial,sans-serif; 
    font-size: 14px; 
    color: #666; 
    background-color: #fff; 
    line-height: 1.7em; 
    font-weight: 500; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
} 

/*@media all{ 
  html { 
    --wp-admin--admin-bar--height: 32px; 
    scroll-padding-top: var(--wp-admin--admin-bar--height);
  } 
}     

html { 
    margin-top: 32px !important;
} */

html { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.vi_mi_2_row { 
    width: 80%; 
    max-width: 1080px; 
    margin: auto; 
    position: relative;
} 

.vi_mi_2_row { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .vi_mi_2_row { 
    padding: 2% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .vi_mi_2_row { 
    padding: 27px 0;
  } 
}     

.vi_mi_2_row:after,.vi_mi_2_row_inner:after { 
    content: ""; 
    display: block; 
    clear: both; 
    visibility: hidden; 
    line-height: 0; 
    height: 0; 
    width: 0;
} 

.vi_mi_2_css_mix_blend_mode_passthrough { 
    mix-blend-mode: unset!important;
} 

.vi_mi_2_column { 
    float: left; 
    background-size: cover; 
    background-position: 50%; 
    position: relative; 
    z-index: 2; 
    min-height: 1px;
} 

.vi_mi_2_column { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .vi_mi_2_column  { 
    margin-right: 5.5%;
  } 

  .vi_mi_2_column  { 
    margin-right: 3%;
  } 
}     

.vi_mi_2_module { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.vi_mi_2_module { 
    -webkit-animation-timing-function: linear; 
    animation-timing-function: linear; 
    -webkit-animation-duration: .2s; 
    animation-duration: .2s;
} 

.vi_mi_2_text { 
    word-wrap: break-word;
} 

.vi_mi_2_module.vi_mi_2_text_align_left { 
    text-align: left;
} 

@media (min-width: 981px){ 
  .vi_mi_2_module  { 
    margin-bottom: 13.174%;
  } 

  .vi_mi_2_module  { 
    margin-bottom: 6.593%;
  } 

  .vi_mi_2_column .vi_mi_2_module:last-child  { 
    margin-bottom: 0;
  } 

  .vi_mi_2_column_4_4  { 
    width: 100%;
  } 
}     

.vi_mi_2_column.et-last-child ,.vi_mi_2_column:last-child  { 
    margin-right: 0!important;
} 

@media (min-width: 981px){ 
  .vi_mi_2_column.et-last-child ,.vi_mi_2_column:last-child  { 
    margin-right: 0!important;
  } 
}     

.vi_mi_2_row_1 { 
    width: 100%; 
    max-width: 100%;
} 

.vi_mi_2_row_1 { 
    background-color: rgba(66,65,65,0.89);
} 

.vi_mi_2_row_1.vi_mi_2_row { 
    padding-top: 40px; 
    padding-right: 10%; 
    padding-bottom: 70px; 
    padding-left: 10%;
} 

.vi_mi_2_space { 
    -webkit-box-sizing: content-box; 
    box-sizing: content-box; 
    height: 23px;
} 

.vi_mi_2_divider { 
    margin: 0 0 30px; 
    position: relative;
} 

.vi_mi_2_divider_1 { 
    width: 78%;
} 

.vi_mi_2_divider_1.vi_mi_2_module { 
    margin-left: auto!important; 
    margin-right: auto!important;
} 

@media (min-width: 981px){ 
  .vi_mi_2_column_4_4 .vi_mi_2_module  { 
    margin-bottom: 2.75%;
  } 
}     

.vi_mi_2_divider:before { 
    content: ""; 
    width: 100%; 
    height: 1px; 
    border-top: 1px solid rgba(0,0,0,.1); 
    position: absolute; 
    left: 0; 
    top: 0; 
    z-index: 10;
} 

.vi_mi_2_divider_1:before { 
    border-top-color: #EDF000; 
    border-top-width: 2px;
} 

.vi_mi_2_divider:after,.vi_mi_2_space:after { 
    content: ""; 
    display: table;
} 

.vi_mi_2_text_inner { 
    position: relative;
    color: #fff;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
} 

.vi_mi_2_text > :last-child  { 
    padding-bottom: 0;
} 

.vi_mi_2_divider_internal { 
    display: inline-block; 
    width: 100%;
} 

h2 { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

h2 { 
    color: #ffffff; 
    padding-bottom: 10px; 
    line-height: 1em; 
    font-weight: 500;
} 

h2 { 
    font-size: 26px;
} 

.vi_mi_2_column_1_4 h2 { 
    font-size: 23px;
} 

.vi_mi_2_text_0 h2 { 
    font-weight: 600; 
    font-size: 30px; 
    color: #ff0000!important;
} 

p { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

p { 
    padding-bottom: 1em;
} 

@media screen and (max-width: 600px){
  .vi_mi_module.vi_mi_text_1.vi_mi_text_align_left{
    padding-bottom: 20px; /* Adjust the padding for mobile devices */
  }
  .vi_mi_logo{
    padding-bottom: 30px;
    width: 55%;
}
}


