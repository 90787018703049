@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,300i,400i,500i,600i,700i,800i&display=swap'); 
@font-face { 
  font-family:ETmodules;
  font-display:block;
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.eot);
  src:url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.eot?#iefix) format("embedded-opentype"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.woff) format("woff"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.ttf) format("truetype"),url(https://appertaxtech.co.zw/react/wp-content/themes/Divi/core/admin/fonts/modules/base/modules.svg#ETmodules) format("svg");
  font-weight:400;
  font-style:normal;
} 
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900,100i,200i,300i,400i,500i,600i,700i,800i,900i&display=swap'); 
  body {  
    font-size:100%;
    background:transparent;
    line-height:1.7em;
    font-family:Open Sans,Arial,sans-serif;
    color:#666;
    font-weight:500;
  }  
* { 
    -webkit-box-sizing: border-box; 
    box-sizing: border-box;
} 

div { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    margin: 0; 
} 

body { 
    padding-top: 0px; 
    padding-right: 0px; 
    padding-bottom: 0px; 
    padding-left: 0px;
} 

body { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

body { 
    line-height: 1;
} 

body { 
    font-family: Open Sans,Arial,sans-serif; 
    font-size: 14px; 
    color: #666; 
    background-color: #fff; 
    line-height: 1.7em; 
    font-weight: 500; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale;
} 

/*@media all{ 
  html { 
    --wp-admin--admin-bar--height: 32px; 
    scroll-padding-top: var(--wp-admin--admin-bar--height);
  } 
}     

html { 
    margin-top: 32px !important;
} */

html { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.se_with_background { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.se_section { 
    position: relative; 
    background-color: #fff; 
    background-position: 50%; 
    background-size: cover;
} 

@media (min-width: 981px){ 
  .se_section { 
    padding: 4% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .se_section { 
    padding: 54px 0;
  } 
}     

.se_section_0.se_section { 
    padding-top: 50px; 
    padding-bottom: 50px; 
    background-color: inherit!important;
} 

div.se_section.se_section_0 { 
    background-blend-mode: overlay; 
    background-image: url(https://appertaxtech.co.zw/react/wp-content/uploads/2024/03/sportstake3-scaled.jpg),linear-gradient(90deg,#c65900 28%,rgba(255,255,255,0.25) 28%)!important;
} 

.se_row { 
    width: 80%; 
    max-width: 1080px; 
    margin: auto; 
    position: relative;
} 

.se_row { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

@media (min-width: 981px){ 
  .se_row { 
    padding: 2% 0;
  } 
}     

@media only screen and (min-width: 1350px){ 
  .se_row { 
    padding: 27px 0;
  } 
}     

/*.se_row_0 { 
    background-image: linear-gradient(90deg,rgba(43,135,218,0) 10%,rgba(0,255,255,0.7) 10%);
}*/
@media screen and (min-width: 601px) {
    .se_row_0 {
        background-image: linear-gradient(90deg,rgba(43,135,218,0) 10%,rgba(0,255,255,0.7) 10%);
    }
  }


.se_row_0 { 
    max-width: 1380px;
} 

.se_row_0.se_row { 
    padding-right: 30px; 
    padding-left: 0px;
} 

.se_row:after,.se_row_inner:after { 
    content: ""; 
    display: block; 
    clear: both; 
    visibility: hidden; 
    line-height: 0; 
    height: 0; 
    width: 0;
} 

.se_css_mix_blend_mode_passthrough { 
    mix-blend-mode: unset!important;
} 

.se_column { 
    float: left; 
    background-size: cover; 
    background-position: 50%; 
    position: relative; 
    z-index: 2; 
    min-height: 1px;
} 

.se_column { 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.se_column_0 { 
    background-color: #000000; 
    border-radius: 20px 20px 20px 20px; 
    overflow: hidden; 
    padding-top: 20px; 
    padding-right: 20px; 
    padding-bottom: 20px; 
    padding-left: 20px;
} 

@media (min-width: 981px){ 
  .se_column  { 
    margin-right: 5.5%;
  } 

  .se_column_2_5  { 
    width: 36.7%;
  } 

  .se_column_3_5  { 
    width: 57.8%;
  } 
}     

.se_row .se_column.et-last-child ,.se_row .se_column:last-child  { 
    margin-right: 0!important;
} 

@media (min-width: 981px){ 
  .se_row .se_column.et-last-child ,.se_row .se_column:last-child  { 
    margin-right: 0!important;
  } 
}     

.se_module { 
    position: relative; 
    background-size: cover; 
    background-position: 50%; 
    background-repeat: no-repeat;
} 

.se_module { 
    -webkit-animation-timing-function: linear; 
    animation-timing-function: linear; 
    -webkit-animation-duration: .2s; 
    animation-duration: .2s;
} 

.se_text { 
    word-wrap: break-word;
} 

.se_module.se_text_align_left { 
    text-align: left;
} 

@media (min-width: 981px){ 
  .se_column_2_5 .se_module  { 
    margin-bottom: 7.493%;
  } 
}     

.se_text_1 { 
    line-height: 0.8em; 
    font-size: 20px;
} 

.se_text_1.se_text { 
    color: #FFFFFF!important;
} 

.se_image { 
    margin-left: auto; 
    margin-right: auto; 
    line-height: 0;
} 

.se_image { 
    display: block;
} 

.se_image_0 { 
    margin-bottom: 10px!important; 
    width: 50%; 
    text-align: center;
} 

.se_text_2 { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 600; 
    margin-bottom: 15px!important;
} 

.se_module.se_text_align_center { 
    text-align: center;
} 

.se_text_2.se_text { 
    color: #000000!important;
} 

.se_button_module_wrapper.se_button_alignment_center { 
    text-align: center;
} 

@media (min-width: 981px){ 
  .se_section div.se_row .se_column .se_module:last-child  { 
    margin-bottom: 0;
  } 
}     

.se_text_3 { 
    margin-bottom: 0px!important;
} 

@media (min-width: 981px){ 
  .se_column_3_5 .se_module  { 
    margin-bottom: 4.758%;
  } 
}     

.se_space { 
    -webkit-box-sizing: content-box; 
    box-sizing: content-box; 
    height: 23px;
} 

.se_divider { 
    margin: 0 0 30px; 
    position: relative;
} 

.se_divider_0 { 
    width: 78%;
} 

.se_divider_0.se_module { 
    margin-left: auto!important; 
    margin-right: auto!important;
} 

.se_divider:before { 
    content: ""; 
    width: 100%; 
    height: 1px; 
    border-top: 1px solid rgba(0,0,0,.1); 
    position: absolute; 
    left: 0; 
    top: 0; 
    z-index: 10;
} 

.se_divider_0:before,.se_divider_1:before,.se_divider_2:before,.se_divider_3:before,.se_divider_4:before { 
    border-top-color: #000000; 
    border-top-width: 2px;
} 

.se_divider:after,.se_space:after { 
    content: ""; 
    display: table;
} 

.se_blurb_0.se_blurb { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 600; 
    font-size: 16px; 
    color: #000000!important; 
    line-height: 1.6em; 
    margin-bottom: 0px!important;
} 

.se_divider_1 { 
    margin-bottom: 10px!important; 
    width: 78%;
} 

.se_divider_1.se_module { 
    margin-left: auto!important; 
    margin-right: 0px!important;
} 

.se_blurb_1.se_blurb { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 600; 
    font-size: 16px; 
    color: #000000!important; 
    line-height: 1.6em; 
    margin-bottom: 0px!important;
} 

.se_divider_2 { 
    margin-bottom: 10px!important; 
    width: 78%;
} 

.se_divider_2.se_module { 
    margin-left: auto!important; 
    margin-right: 0px!important;
} 

.se_blurb_2.se_blurb { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 600; 
    font-size: 16px; 
    color: #000000!important; 
    line-height: 1.6em; 
    margin-bottom: 0px!important;
} 

.se_divider_3 { 
    margin-bottom: 10px!important; 
    width: 78%;
} 

.se_divider_3.se_module { 
    margin-left: auto!important; 
    margin-right: 0px!important;
} 

.se_blurb_3.se_blurb { 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif; 
    font-weight: 600; 
    font-size: 16px; 
    color: #000000!important; 
    line-height: 1.6em; 
    margin-bottom: 0px!important;
} 

.se_divider_4 { 
    margin-bottom: 10px!important; 
    width: 78%;
} 

.se_divider_4.se_module { 
    margin-left: auto!important; 
    margin-right: 0px!important;
} 

.se_text_inner { 
    position: relative;
    color: #fff;
} 

.se_text > :last-child  { 
    padding-bottom: 0;
    color: white;
} 

span { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

.se_image .se_image_wrap  { 
    display: inline-block; 
    position: relative; 
    max-width: 100%;
} 

.se_image_wrap .se_image_wrap_column1{
    width: 80%;
}


.se_image_0 .se_image_wrap  { 
    border-radius: 100px 100px 100px 100px; 
    overflow: hidden;
} 

a:where(:not(.wp-element-button)) { 
    text-decoration: underline;
} 

a { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

a { 
    color: #2ea3f2;
} 

a { 
    text-decoration: none;
} 

.se_button { 
    font-size: 20px; 
    font-weight: 500; 
    padding: .3em 1em; 
    line-height: 1.7em!important; 
    background-color: transparent; 
    /*background-size: cover;*/ 
    background-position: 50%; 
    background-repeat: no-repeat; 
    border: 2px solid; 
    border-radius: 3px; 
    -webkit-transition-duration: .2s; 
    transition-duration: .2s; 
    -webkit-transition-property: all!important; 
    transition-property: all!important;
    box-sizing: border-box;
} 

.se_button { 
    position: relative;
} 

.se_button_0 { 
    transition: all 300ms ease 0ms;
} 

.se_button_module_wrapper > a  { 
    display: inline-block;
} 

.se_button_0_wrapper .se_button_0  { 
    padding-top: 12px!important; 
    padding-right: 5px!important; 
    padding-bottom: 12px!important; 
    padding-left: 5px!important;
    box-sizing: border-box !important;
} 

.se_button_0  { 
    color: #ffffff!important; 
    border-width: 0px!important; 
    border-radius: 300px; 
    font-size: 13px; 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif!important; 
    font-weight: 600!important; 
    background-image: linear-gradient(90deg,#ff6100 1%,#e2b900 100%);
} 

.se_button:after,.se_button:before { 
    font-size: 32px; 
    line-height: 1em; 
    content: "\35"; 
    opacity: 0; 
    position: absolute; 
    margin-left: -1em; 
    -webkit-transition: all .2s; 
    transition: all .2s; 
    text-transform: none; 
    -webkit-font-feature-settings: "kern" off; 
    font-feature-settings: "kern" off; 
    font-variant: none; 
    font-style: normal; 
    font-weight: 400; 
    text-shadow: none;
} 

.se_button:before { 
    display: none;
} 

body #page-container .se_section .se_button_0::before, body #page-container .se_section .se_button_0::after, body #page-container .se_section .se_button_1::before, body #page-container .se_section .se_button_1::after, body #page-container .se_section .se_button_2::before, body #page-container .se_section .se_button_2::after { 
    display: none!important;
} 

#et-info-email:before,#et-info-phone:before,#et_search_icon:before,.comment-reply-link:after, .et-cart-info span::before,.et-pb-arrow-next:before,.et-pb-arrow-prev:before, .et-social-icon a::before, .et_audio_container .mejs-playpause-button button::before, .et_audio_container .mejs-volume-button button::before,.et_overlay:before, .et_password_protected_form .et_submit_button::after,.se_button:after,.se_contact_reset:after,.se_contact_submit:after,.se_font_icon:before,.se_newsletter_button:after,.se_pricing_table_button:after,.se_promo_button:after,.se_testimonial:before,.se_toggle_title:before, .form-submit .se_button::after,.mobile_menu_bar:before,a.se_more_button:after { 
    font-family: ETmodules!important; 
    font-style: normal; 
    font-weight: 400; 
    -webkit-font-feature-settings: normal; 
    font-feature-settings: normal; 
    font-variant: normal; 
    text-transform: none; 
    line-height: 1; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 
    text-shadow: 0 0; 
    direction: ltr;
} 

.se_button_0,.se_button_0:after,.se_button_1,.se_button_1:after,.se_button_2,.se_button_2:after { 
    transition: all 300ms ease 0ms;
} 

a,a:hover { 
    text-decoration: none;
} 

.se_button:hover { 
    background-color: hsla(0,0%,100%,.2);
} 

.se_button:hover, .se_module .se_button:hover { 
    border: 2px solid transparent; 
    padding: .3em 2em .3em .7em;
} 

.se_bg_layout_light.se_button:hover { 
    background-color: rgba(0,0,0,.05);
} 

.se_button_0_wrapper .se_button_0 , .se_button_0_wrapper .se_button_0:hover { 
    padding-top: 12px!important; 
    padding-right: 5px!important; 
    padding-bottom: 12px!important; 
    padding-left: 5px!important;
} 

.se_button:hover:after { 
    opacity: 1; 
    margin-left: 0;
} 

.se_button_1 { 
    transition: all 300ms ease 0ms;
} 

.se_button_0_wrapper .se_button_0, .se_button_0_wrapper .se_button_0:hover, .se_button_1_wrapper .se_button_1, .se_button_1_wrapper .se_button_1:hover, .se_button_2_wrapper .se_button_2, .se_button_2_wrapper .se_button_2:hover { 
    padding-top: 12px!important; 
    padding-right: 5px!important; 
    padding-bottom: 12px!important; 
    padding-left: 5px!important;
} 

.se_button_1  { 
    color: #FFFFFF!important; 
    border-width: 0px!important; 
    border-radius: 300px; 
    font-size: 13px; 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif!important; 
    font-weight: 600!important; 
    background-image: linear-gradient(90deg,#1600db 0%,#8300e9 100%);
} 

#et-info-email::before, #et-info-phone::before, #et_search_icon::before, .comment-reply-link::after, .et-cart-info span::before, .et-pb-arrow-next::before, .et-pb-arrow-prev::before, .et-social-icon a::before, .et_audio_container .mejs-playpause-button button::before, .et_audio_container .mejs-volume-button button::before, .et_overlay::before, .et_password_protected_form .et_submit_button::after, .se_button::after, .se_contact_reset::after, .se_contact_submit::after, .se_font_icon::before, .se_newsletter_button::after, .se_pricing_table_button::after, .se_promo_button::after, .se_testimonial::before, .se_toggle_title::before, .form-submit .se_button::after, .mobile_menu_bar::before, a.se_more_button::after { 
    font-family: ETmodules!important; 
    font-style: normal; 
    font-weight: 400; 
    -webkit-font-feature-settings: normal; 
    font-feature-settings: normal; 
    font-variant: normal; 
    text-transform: none; 
    line-height: 1; 
    -webkit-font-smoothing: antialiased; 
    -moz-osx-font-smoothing: grayscale; 
    text-shadow: 0 0; 
    direction: ltr;
} 

.se_button_2 { 
    transition: all 300ms ease 0ms;
} 

.se_button_2  { 
    color: #FFFFFF!important; 
    border-width: 0px!important; 
    border-radius: 300px; 
    font-size: 13px; 
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif!important; 
    font-weight: 600!important; 
    background-image: linear-gradient(90deg,#8300e9 0%,#e00b00 79%);
} 

.se_divider_internal { 
    display: inline-block; 
    width: 100%;
} 

.se_blurb_content { 
    max-width: 550px; 
    margin: 0 auto; 
    position: relative; 
    text-align: center; 
    word-wrap: break-word; 
    width: 100%;
} 

.se_blurb_position_left .se_blurb_content  { 
    display: table;
} 

.se_blurb_position_left .se_blurb_content  { 
    text-align: left;
} 

.se_blurb_0 .se_blurb_content  { 
    max-width: 100%;
} 

.se_blurb_1 .se_blurb_content  { 
    max-width: 100%;
} 

.se_blurb_2 .se_blurb_content  { 
    max-width: 100%;
} 

.se_blurb_3 .se_blurb_content  { 
    max-width: 100%;
} 

h2 { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

h2 { 
    color: #333; 
    padding-bottom: 10px; 
    line-height: 1em; 
    font-weight: 500;
} 

h2 { 
    font-size: 26px;
} 

.se_column_2_5 h2  { 
    font-size: 23px;
} 

.se_text_0 h2  { 
    font-weight: 600; 
    font-size: 32px; 
    color: #ff0000!important;
} 

p { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

p { 
    padding-bottom: 1em;
} 

p:not(.has-background):last-of-type { 
    padding-bottom: 0;
} 

img { 
    margin: 0; 
    padding: 0; 
    border: 0; 
    outline: 0; 
    font-size: 100%; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
    vertical-align: baseline; 
    background: transparent;
} 

img { 
    max-width: 100%; 
    height: auto;
} 

.se_image img  { 
    position: relative;
} 

.se_text_3 h2  { 
    font-weight: 600; 
    font-size: 32px; 
    color: #ff0000!important; 
    text-align: center;
} 

.se_main_blurb_image { 
    display: inline-block; 
    margin-bottom: 30px; 
    line-height: 0; 
    max-width: 100%;
} 

.se_blurb_position_left .se_main_blurb_image  { 
    width: 32px; 
    display: table-cell; 
    line-height: 0;
} 

.se_blurb_position_left .se_blurb_container  { 
    display: table-cell; 
    vertical-align: top;
} 

.se_blurb_position_left .se_blurb_container  { 
    padding-left: 15px;
} 

.se_blurb.se_text_align_left .se_blurb_content .se_blurb_container  { 
    text-align: left;
} 

.se_text_1.se_text a  { 
    color: #FFFFFF!important;
} 

.se_blurb .se_image_wrap  { 
    display: block; 
    margin: auto;
} 

.se_blurb_0 .se_main_blurb_image .se_image_wrap  { 
    width: 110px;
} 

.se_blurb_0.se_blurb .se_blurb_description  { 
    text-align: center;
} 

.se_blurb_1 .se_main_blurb_image .se_image_wrap  { 
    width: 110px;
} 

.se_blurb_1.se_blurb .se_blurb_description  { 
    text-align: center;
} 

.se_blurb_2 .se_main_blurb_image .se_image_wrap  { 
    width: 110px;
} 

.se_blurb_2.se_blurb .se_blurb_description  { 
    text-align: center;
} 

.se_blurb_3 .se_main_blurb_image .se_image_wrap  { 
    width: 110px;
} 

.se_blurb_3.se_blurb .se_blurb_description  { 
    text-align: center;
} 

.se_main_blurb_image img  { 
    border-radius: inherit;
} 

.et-waypoint:not(.se_counters) { 
    opacity: 0;
} 

.se_blurb_position_left .se_main_blurb_image img  { 
    width: inherit;
} 

.et-waypoint.se_animation_top.et-animated,.se_animation_top.et-animated { 
    opacity: 1; 
    -webkit-animation: fadeTop 1s cubic-bezier(.77,0,.175,1) 1; 
    animation: fadeTop 1s cubic-bezier(.77,0,.175,1) 1;
    width: 80%;
} 

.se_blurb_content p:last-of-type  { 
    padding-bottom: 0;
} 

.se_blurb_0.se_blurb p  { 
    line-height: 1.6em;
} 

.se_blurb_1.se_blurb p  { 
    line-height: 1.6em;
} 

.se_blurb_2.se_blurb p  { 
    line-height: 1.6em;
} 

.se_blurb_3.se_blurb p  { 
    line-height: 1.6em;
} 


@keyframes fadeTop { 
  0% {  
      opacity: 0; 
      -webkit-transform: translateY(-10%); 
      transform: translateY(-10%); 
      opacity: 0; 
      transform: translateY(-10%); 
  }  
  100% {  
      opacity: 1; 
      -webkit-transform: translateX(0); 
      transform: translateX(0); 
      opacity: 1; 
      transform: translateX(0px); 
  }  

} 

.se_module {
    display: flex;
    flex-direction: column;
  }
  
  .se_text_inner {
    display: flex;
    flex-direction: column;
  }
  .se_nav_button {
    padding: 5px 15px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
    width: fit-content;
    font-size: 18px;
    color: rgb(255, 255, 255);
    margin-bottom: 5px;
  }
  
  .se_nav_button_border {
    border: 1px solid rgb(255, 255, 255);
    border-radius: 20px;
  }
  
  .se_button_text {
    color: rgb(255, 255, 255);
    text-decoration: none;
  }

  .se_image img {
    position: relative;
    width: 60%;
    border-radius: 100px;
}

.se_logo{
    width: 60%;
}

  @media screen and (max-width: 600px) {
    .se_row_0 {
        background-color: #00ffff;
        padding-right: 25px !important;
        padding-left: 25px !important;
        padding-top: 30px !important;
    }
    .se_image_0 {
        padding-top: 20px !important;
        margin-bottom: 10px !important;
        width: 50%;
        text-align: center;
    }
    .se_image img {
        position: relative;
        width: 80%;
        border-radius: 100px;
    }
    .se_text_inner {
        display: flex;
        color: rgb(0, 0, 0);
        flex-direction: column;
        font-size: 14px;
    }
    .se_text_inner_p1{
        color: white;
    }
    .se_button_module_wrapper.se_button_alignment_center {
        text-align: center;
        padding-bottom: 20px !important;
    }
    .se_blurb_0 .se_main_blurb_image .se_image_wrap {
        width: 60px !important;
    }
    .se_blurb_0.se_blurb .se_blurb_description {
        text-align: center;
        font-size: 14px !important;
    }
    .se_blurb_1 .se_main_blurb_image .se_image_wrap {
        width: 60px !important;
    }
    .se_blurb_1.se_blurb .se_blurb_description {
        text-align: center;
        font-size: 14px !important;
    }
    .se_blurb_2 .se_main_blurb_image .se_image_wrap {
        width: 60px !important;
    }
    .se_blurb_2.se_blurb .se_blurb_description {
        text-align: center;
        font-size: 14px !important;
    }
    .se_blurb_3 .se_main_blurb_image .se_image_wrap {
        width: 60px !important;
    }
    .se_blurb_3.se_blurb .se_blurb_description {
        text-align: center;
        font-size: 14px !important;
    }
    .se_divider_0 {
        margin-bottom: 10px !important;
    }
    .se_row .se_column.et-last-child, .se_row .se_column:last-child {
        padding-top: 30px !important;
    }
    div.se_section.se_section_0 {
        background-image: url(https://appertaxtech.co.zw/react/wp-content/uploads/2024/03/sportstake3-scaled.jpg), linear-gradient(90deg, #c65900 50%, rgba(255, 255, 255, 0.25) 28%) !important;
    }
    .se_logo{
        width: 70%;
        padding-bottom: 20px;
    }
    
  }

